import React from "react";
//Components
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Title from "../Title";
import Paragraph from "../Paragraph";

export default function Feed() {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          display: { xs: "none", md: "none", lg: "block" },
          paddingTop: "4rem",
          textAlign: "left"
        }}
      >
        <Grid
          lg={9}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <Title>Desarrollo e Innovación</Title>
          </Box>
          <Paragraph>
            Los Proyectos impulsados por la Corporación de Desarrollo e
            Innovación van en directa relación con la misión que nos hemos
            propuesto: mejorar la calidad de vida de las y los vecinos de la
            comuna de Rancagua, entendiendo que a través de la innovación
            social, tecnología, emprendimiento, fomento, medio ambiente y otros
            ámbitos avanzaremos sostenidamente en aportar también en la
            construcción de una mejor comuna; todo esto, a partir de un trabajo
            colectivo con otras entidades e instituciones, sean éstas
            municipales, públicas y privadas.
            <br />
            <br />
            <br />
            Adicionalmente, la Agenda del Desarrollo 2030 de Naciones Unidas es
            también una inspiración para el trabajo de CDIR, ya que, con sus
            principios rectores para las mejoras universales, ofrece directrices
            que creemos fundamentales para el desarrollo de la comuna de
            Rancagua. Es por esto que los Objetivos de Desarrollo Sostenible
            (ODS) son también parte de nuestros pilares y principios, tales como
            democracia, igualdad, equidad, universalidad, inclusión, perspectiva
            de género, respeto por y para las personas, sustentabilidad y medio
            ambiente, entre otras.
          </Paragraph>
        </Grid>
      </Grid>
      {/* MOBILE*/}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          display: { xs: "block", md: "block", lg: "none" },
          paddingTop: "2rem",
          textAlign: "left"
        }}
      >
        <Grid
          xs={10.5}
          md={10.5}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box sx={{ paddingBottom: "20px" }}>
            <Title>Desarrollo e Innovación</Title>
          </Box>

          <Paragraph>
            Los Proyectos impulsados por la Corporación de Desarrollo e
            Innovación van en directa relación con la misión que nos hemos
            propuesto: mejorar la calidad de vida de las y los vecinos de la
            comuna de Rancagua, entendiendo que a través de la innovación
            social, tecnología, emprendimiento, fomento, medio ambiente y otros
            ámbitos avanzaremos sostenidamente en aportar también en la
            construcción de una mejor comuna; todo esto, a partir de un trabajo
            colectivo con otras entidades e instituciones, sean éstas
            municipales, públicas y privadas.
            <br />
            <br />
            Adicionalmente, la Agenda del Desarrollo 2030 de Naciones Unidas es
            también una inspiración para el trabajo de CDIR, ya que, con sus
            principios rectores para las mejoras universales, ofrece directrices
            que creemos fundamentales para el desarrollo de la comuna de
            Rancagua. Es por esto que los Objetivos de Desarrollo Sostenible
            (ODS) son también parte de nuestros pilares y principios, tales como
            democracia, igualdad, equidad, universalidad, inclusión, perspectiva
            de género, respeto por y para las personas, sustentabilidad y medio
            ambiente, entre otras.
          </Paragraph>
        </Grid>
      </Grid>
    </>
  );
}
