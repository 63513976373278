import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Box, Typography, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

//Components
import Paragraph from "components/Paragraph";
import Loading from "components/Loading";
import Title from "components/Title";
import { NewsDescriptionSanitize } from "components/SanitizeHTML";

//Providers
import { getNewsByUrlKey, News } from "providers/news";
import { initGA, logPageView } from "helpers/analytics";

export default function NoticiasDetalle() {
  const { newsId } = useParams();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [newsData, setNewsData] = useState<News | null>(null);
  const [coverLoaded, setCoverLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const data = await getNewsByUrlKey(newsId);
      data ? setNewsData(data) : setNewsData(null);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);

  useEffect(() => {
    if (newsData?.cover) {
      const coverImage = new Image();
      coverImage.src = newsData.cover.src;
      coverImage.onload = () => {
        setCoverLoaded(true);
      };
    } else {
      setCoverLoaded(true);
    }
  }, [newsData]);

  function extractYouTubeVideoId(url: string) {
    /* try {
            const match = url.match(/youtube\.com\/watch\?v=(.*)/);
            if (match && match[1]) {
                return match[1];
            } else {
                return url.includes("youtube.com/embed/")
                    ? url.split("/embed/")[1]
                    : null;
            }
        } catch (error) {
            console.error("error youtube", error);
            return null;
        } */
    return null;
  }

  useEffect(() => {
    if (newsData) {
      const imagePromises = newsData.gallery
        ? newsData.gallery.map((imagen) => {
            return new Promise<void>((resolve) => {
              const img = new Image();
              img.src = `${imagen.src}?w=164&h=164&fit=crop&auto=format`;
              img.onload = () => resolve();
            });
          })
        : [];
      newsData.gallery
        ? Promise.all(imagePromises).then(() => {
            setImagesLoaded(true);
          })
        : setImagesLoaded(true);
    }
  }, [newsData]);
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  /*  if (!newsData || !coverLoaded || !imagesLoaded) {
    return <Loading />;
  }
 */
  const formatDate = (dateString: string) => {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const lightboxImages =
    newsData && newsData.gallery
      ? newsData.gallery.map((imagen) => ({
          src: imagen.src,
          alt: newsData.title
        }))
      : [];

  return !newsData || !coverLoaded || !imagesLoaded ? (
    <Loading />
  ) : (
    <>
      <Grid
        lg={8.75}
        md={10.5}
        xs={10.5}
        container
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left"
        }}
      >
        {newsData.cover && (
          <img
            src={newsData.cover.src}
            alt={newsData.cover.title}
            style={{
              width: isMobile ? "100%" : "1375px",
              height: isMobile ? "auto" : "707px",
              minHeight: !isMobile ? "707px" : "auto"
            }}
          />
        )}
        <Grid lg={12}>
          <Box
            sx={{
              paddingTop: "58px"
            }}
          >
            <Title>{newsData.title}</Title>
          </Box>
        </Grid>
        <Grid lg={12}>
          <Typography
            variant="subtitle2"
            sx={{
              "@media screen and (max-width: 767px)": {
                fontSize: "14px"
              },
              "@media screen and (min-width: 768px)": {
                fontSize: "20px"
              },
              fontWeight: "400",
              color: "#757779"
            }}
          >
            {formatDate(newsData.publicationDate.substring(0, 10))}
          </Typography>
        </Grid>
        <Grid lg={12}>
          <Box>
            <NewsDescriptionSanitize description={newsData.description} />
          </Box>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <div style={{ width: "100%", paddingTop: "77px" }}>
            <ImageList cols={isMobile ? 2 : 3} rowHeight={isMobile ? 200 : 450}>
              {newsData.gallery.map((imagen, index) => (
                <ImageListItem key={index}>
                  <img
                    src={`${imagen.src}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${imagen.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={newsData.title}
                    loading="lazy"
                    onClick={() => {
                      setLightboxOpen(true);
                      setSelectedImageIndex(index);
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </Grid>
        {/* {newsData.video && (
          <Grid lg={12}>
            <Box sx={{ paddingTop: "110px" }}>
              <iframe
                width={isMobile ? 360 : 917}
                height={isMobile ? 218.564 : 558}
                src={
                  newsData?.video.includes("youtube.com/embed/")
                    ? newsData.video
                    : `https://www.youtube.com/embed/${extractYouTubeVideoId(
                        newsData?.video
                      )}`
                }
                title={newsData.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Box>
          </Grid>
        )} */}
        {newsData.externalUrl && newsData.externalUrl.length > 0 && (
          <Grid lg={12} md={12} xs={12}>
            <Box
              sx={{
                paddingTop: "108px"
              }}
            >
              <Title>En prensa</Title>
            </Box>
          </Grid>
        )}

        {newsData.externalUrl &&
          newsData.externalUrl.map((external, index) => (
            <Grid key={index} lg={12}>
              <div style={{ width: "100%", paddingTop: "16px" }}>
                <ImageList
                  cols={isMobile ? 1 : 3}
                  rowHeight={isMobile ? 200 : 450}
                >
                  <Link
                    href={external.url}
                    target="_blank"
                    sx={{ textTransform: "none", textDecoration: "none" }}
                  >
                    <ImageListItem>
                      <img
                        src={external.image.src}
                        srcSet={external.image.src}
                        alt={external.image.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Link>
                </ImageList>
                <Link
                  href={external.url}
                  target="_blank"
                  sx={{ textTransform: "none", textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      paddingTop: "22px",
                      paddingBottom: "169px",
                      fontWeight: "700",
                      color: "black"
                    }}
                  >
                    <Paragraph>{external.title}</Paragraph>
                  </Box>
                </Link>
              </div>
            </Grid>
          ))}
      </Grid>
      {/* Lightbox component */}
      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={lightboxImages}
        />
      )}
    </>
  );
}
