import React, { useEffect, useState } from "react";
//Components
import { Box, Grid, Typography } from "@mui/material";
import Title2 from "../../components/Title2";
import Paragraph from "../../components/Paragraph";
//Providers
import { Department, getDepartmentData } from "../../providers/team";
import { getTeamBanner } from "../../providers/team";
import { initGA, logPageView } from "../../helpers/analytics";
import { getDirectory } from "../../providers/directory";

export default function NosotrasNosotros() {
  const [team, setTeam] = useState<Department[]>([]);
  const teamBanner = getTeamBanner();
  const directory = getDirectory();

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  useEffect(() => {
    async function fetchData() {
      const teamData = await getDepartmentData();
      setTeam(teamData);
    }
    fetchData();
  }, []);
  const MAX_CARACTERS = 27;
  console.log("team", team);
  return (
    <>
      <Box>
        {teamBanner.map((banner, index) => (
          <img
            key={index}
            src={banner.src}
            alt={banner.alt}
            style={{ width: "100%", height: "auto" }}
          />
        ))}
      </Box>
      <Grid container sx={{ textAlign: "left" }}>
        <Grid
          xs={10.5}
          md={10.5}
          lg={9}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: "53px", md: "53px", lg: "106px" },
              paddingBottom: { xs: "61px", md: "61px", lg: "73px" }
            }}
          >
            <Title2>Nosotras y Nosotros</Title2>
            <Box sx={{ paddingTop: { xs: "30px", md: "30px", lg: "30px" } }}>
              <Paragraph>
                La Corporación de Desarrollo e Innovación está conformada por el
                siguiente equipo humano:
              </Paragraph>
            </Box>
          </Box>
          <Box
            sx={{
              paddingBottom: { xs: "24px", md: "24px", lg: "24px" }
            }}
          >
            <Title2>Presidente Corporación</Title2>
            <Box sx={{ paddingTop: { xs: "30px", md: "30px", lg: "30px" } }}>
              <Paragraph>
                Alcalde de la Ilustre Municipalidad de Rancagua, Emerson
                Avendaño Llanca.
              </Paragraph>

              {/*  <img
                src="https://firebasestorage.googleapis.com/v0/b/cdir-web.appspot.com/o/cdir%2FIMG_5888.png?alt=media&token=3f0aed5b-6481-4751-9fd8-111771d7afee"
                alt="Alcalde Juan Ramón Godoy"
                width="100%"
                height="auto"
                style={{ margin: "1rem 0", padding: "1rem" }}
              /> */}
            </Box>
          </Box>
          {/*  <Box sx={{ paddingBottom: "90px" }}>
            <Title2>Directorio</Title2>
            <Grid container spacing={1}>
              {directory.map((directory, index) => (
                <Grid item xs={12} md={12} lg={6} key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%"
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: { xs: "22px", md: "22px", lg: "25px" },
                        fontWeight: "700",
                        paddingBottom: "10px",
                        paddingTop: "30px",
                        textAlign: "left"
                      }}
                    >
                      {(() => {
                        const words = directory.company.split(" ");
                        let currentLine = "";
                        const result = [];

                        for (let i = 0; i < words.length; i++) {
                          const word = words[i];

                          if (
                            currentLine.length + word.length + 1 <=
                            MAX_CARACTERS
                          ) {
                            currentLine +=
                              (currentLine === "" ? "" : " ") + word;
                          } else {
                            result.push(currentLine);
                            currentLine = word;
                          }

                          if (i === words.length - 1) {
                            result.push(currentLine);
                          }
                        }

                        return result.map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      })()}
                    </Typography>
                    <Paragraph>{directory.name}</Paragraph>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box> */}
        </Grid>
        {/*
        <Grid xs={12} md={12}>
          <Container>
            <img
              src="/organigrama.png"
              alt="organigrama"
              style={{ paddingBottom: "90px", maxWidth: "100%" }}
            />
          </Container>
        </Grid>
        */}
        <Grid container spacing={1} sx={{ textAlign: "left" }}>
          <Grid
            item
            xs={10.5}
            md={10.5}
            lg={9}
            sx={{
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Box
              sx={{
                paddingBottom: { lg: "-28px" }
              }}
            >
              <Title2>Equipo</Title2>
            </Box>
          </Grid>
          <Grid
            container
            lg={8.8}
            md={9.5}
            xs={9.5}
            sx={{
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            {team.map((teamData, index) => (
              <Grid key={index} item xs={12} md={12} lg={4}>
                <Box
                  sx={{
                    paddingBottom: {
                      xs: index === team.length - 1 ? "131px" : 0,
                      md: index === team.length - 1 ? "131px" : 0,
                      lg: index === team.length - 1 ? "531px" : 0
                    }
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: "25px",
                      paddingBottom: { xs: "28px", md: "28px", lg: "30px" }
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "26px", md: "26px", lg: "31px" },
                        fontWeight: "700",
                        paddingTop: { xs: "30px", md: "30px", lg: "58px" }
                      }}
                    >
                      {(() => {
                        const words = (teamData?.name || "").split(" ");
                        let currentLine = "";
                        const result = [];

                        for (let i = 0; i < words.length; i++) {
                          const word = words[i];

                          if (
                            currentLine.length + word.length + 1 <=
                            MAX_CARACTERS
                          ) {
                            currentLine +=
                              (currentLine === "" ? "" : " ") + word;
                          } else {
                            result.push(currentLine);
                            currentLine = word;
                          }

                          if (i === words.length - 1) {
                            result.push(currentLine);
                          }
                        }

                        return result.map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      })()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: {
                        lg:
                          teamData.name.length === 13
                            ? "70px"
                            : teamData.name.length === 19 ||
                              teamData.name.length === 29
                            ? "35px"
                            : "0"
                      }
                    }}
                  >
                    {teamData.team
                      ?.slice()
                      .filter((item) => item !== null && item !== undefined)
                      .sort((a, b) =>
                        a.level !== undefined && b.level !== undefined
                          ? a.level - b.level
                          : 0
                      )
                      .map((item, itemIndex) => {
                        return (
                          <React.Fragment key={itemIndex}>
                            <Typography
                              variant="h2"
                              sx={{
                                fontSize: {
                                  xs: "22px",
                                  md: "22px",
                                  lg: "25px"
                                },
                                fontWeight: "700",
                                paddingTop: "10px"
                              }}
                            >
                              {/*       <pre>{JSON.stringify(item)}</pre> */}
                              {item?.name || ""}
                            </Typography>
                            <Typography
                              variant="h1"
                              sx={{
                                fontSize: {
                                  xs: "14px",
                                  md: "14px",
                                  lg: "20px"
                                },
                                fontWeight: "400"
                              }}
                            >
                              {item?.position || ""}
                            </Typography>
                          </React.Fragment>
                        );
                      })}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
