import React, { useEffect, useState, Suspense, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { validate as uuidValidate } from "uuid";
import { useLocalStorage } from "usehooks-ts";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Link,
  Paper
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";

//Icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Providers
import { getCategories } from "providers/customs/categories";
import { getSponsors, getSchools } from "providers/customs/sponsors";
import { getSocialDataFilter, Social } from "providers/socialnews";
import {getParticipant, Participant} from "../../providers/Participant";


// Components
import EventCountdown from "components/EventCountdown";

// Styles
import { AdviseBox } from "./styles";
import "./styles.scss";
import { getVotesData, Votes } from "providers/votes";

const CURRENT_EVENT = "CARROS2023";
const QR_KEY = "scXItcC0yMw";
// const VOTE_URL = `https://preprod.d1wr6xsdx5pqbb.amplifyapp.com/votacion/${CURRENT_EVENT}`;
const VOTE_URL = `https://descubrerancagua.cl/votacion/${CURRENT_EVENT}`;

interface HistoriaEnTusManosProps {
  data: any;
}

export default function WrappedHistoriaEnTusManos(
  props: HistoriaEnTusManosProps
) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={"6Ldr2wgpAAAAAANOFt8Iwfom6rEupuFZpBdfJoyr"}
    >
      <HistoriaEnTusManos {...props} />
    </GoogleReCaptchaProvider>
  );
}

function HistoriaEnTusManos({ data }: HistoriaEnTusManosProps) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [countVotes, setCountVotes] = useState<Votes[]>([]);
  const categories = getCategories();
  const sponsors = getSponsors();
  const schools = getSchools();
  const [searchParams] = useSearchParams();
  const [social, setSocial] = useState<Social[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [voteInProgress, setVoteInProgress] = useState(false);
  const [startIndexSponsors, setStartIndexSponsors] = useState(0);
  const [voteLink, setVoteLink] = useState("");
  const [qrToken, setQrToken] = useLocalStorage<any>("tk", "");
  const [activeCountdown, setActiveCountdown] = useState(true);
  const[participantsVotes, setParticipantsVotes] = useState<Participant[]>([]);
  const endDate = new Date("2023-11-18T19:30:00").getTime();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const participants = await getParticipant();
        const votesData = await getVotesData();
        const participantsWithVotes = participants.map(participant => {
          const votes = votesData.find(vote => vote.option === participant.urlKey);
          return {
            ...participant,
            numberVotes: votes ? votes.count : 0,
          };
        });
        const sortedParticipants = participantsWithVotes.sort((a, b) => b.numberVotes - a.numberVotes);

        const top3Participants = sortedParticipants.slice(0, 3);

        setParticipantsVotes(top3Participants);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime >= endDate) {
        setActiveCountdown(false);
        clearInterval(intervalId);
      }
    }, 100); // Verificar cada segundo
    return () => clearInterval(intervalId);
  }, [endDate]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha(CURRENT_EVENT);

    setRecaptchaToken(token);
    /* setVoteInProgress(true); */
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    const qrKey = searchParams.get(QR_KEY);
    /*  const valid = uuidValidate(qrChecked); */
    if (qrKey && qrKey.trim() === "=") {
      const key = uuidv4();
      setQrToken(key);
      setVoteLink(`${VOTE_URL}?qr=${key}`);
      /* setQrChecked(uuidv4()); */
    } else if (qrToken.trim() !== "" && uuidValidate(qrToken.trim() || "")) {
      setVoteLink(`${VOTE_URL}?qr=${qrToken}`);
    } else {
      setVoteLink(`${VOTE_URL}`);
      //setQrToken("");
    }
    /* setSource(qrKey); */
    /* const param = currentQueryParameters.get("scXItcC0yMw"); */
    /* console.log("param", param); */
    /*  if (newRel) setRel(newRel.concat(`&qr=${qrRel}`)); */
  }, [searchParams]);

  useEffect(() => {
    async function fetchData() {
      const socialData = await getSocialDataFilter();
      const votesData = await getVotesData();
      setCountVotes(votesData);
      setSocial(socialData);
    }
    fetchData();
  }, []);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const nextLogos = () => {
    const numVisibleLogos = getNumVisibleLogos();
    setStartIndex(
      (prevIndex) => (prevIndex + 1) % (schools.length - numVisibleLogos + 1)
    );
  };

  const goToVoteLink = useCallback(() => {
    voteLink !== "" && window.open(voteLink, "_blank");
  }, [voteLink]);

  const onClickVote = useCallback(async () => {
    try {
      if (recaptchaToken !== "") {
        /* setVoteInProgress(true); */

        // Auth user

        // save user in localstorage

        // redirect to vote page

        setTimeout(goToVoteLink, 0);
      } else {
        console.log("not valid token");
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [recaptchaToken]);

  const prevLogos = () => {
    const numVisibleLogos = getNumVisibleLogos();
    setStartIndex(
      (prevIndex) =>
        (prevIndex - 1 + (schools.length - numVisibleLogos + 1)) %
        (schools.length - numVisibleLogos + 1)
    );
  };

  const getNumVisibleLogos = () => {
    return window.innerWidth >= 600 ? 5 : 2;
  };
  const nextSponsors = () => {
    const numVisibleSponsors = getNumVisibleSponsors();
    setStartIndexSponsors(
      (prevIndex) =>
        (prevIndex + 1) % (sponsors.length - numVisibleSponsors + 1)
    );
  };

  const prevSponsors = () => {
    const numVisibleSponsors = getNumVisibleSponsors();
    setStartIndexSponsors(
      (prevIndex) =>
        (prevIndex - 1 + (sponsors.length - numVisibleSponsors + 1)) %
        (sponsors.length - numVisibleSponsors + 1)
    );
  };

  const getNumVisibleSponsors = () => {
    return window.innerWidth >= 600 ? 5 : 2;
  };

  const visibleSponsors = sponsors.slice(
    startIndexSponsors,
    startIndexSponsors + getNumVisibleSponsors()
  );
  const visibleLogos = schools.slice(
    startIndex,
    startIndex + getNumVisibleLogos()
  );
  /* console.log("data ->", data); */
  return (
    <>
      <Box
        style={{ minHeight: "50vh" }}
        sx={{
          display: { xs: "flex", md: "flex", lg: "none" }
          /* paddingBottom: "1rem" */
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fbanners%2FEtapa-2%2Fwebp%2Fportada-mobile-1.webp?alt=media&token=7643cf08-1de4-4a25-a72e-63eb1e0c4e64"
          alt="La Historia Está en tus manos"
          style={{ width: "100%", height: "465px" }}
          loading="lazy"
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "none", lg: "flex" },
          paddingBottom: "2rem"
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fbanners%2FEtapa-2%2Fwebp%2Fportada-web-1.webp?alt=media&token=dd9e235e-74cd-487a-8cd4-3737534f19ec"
          alt="La Historia Está en tus manos"
          style={{ width: "100%", height: "auto" }}
          loading="lazy"
        />
      </Box>
      <AdviseBox
        style={{
          margin: `0 0 ${isMobile ? "2rem" : "4rem"} 0`
        }}
      >
        {activeCountdown === false && (
          <React.Fragment>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "14px", lg: "20px" },
                fontWeight: "400",
                textAlign: "center",
                fontStyle: "normal"
              }}
            >
              ¡El evento ya finalizó!
            </Typography>
            
          </React.Fragment>
        )}
        {activeCountdown === true && (
          <React.Fragment>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "14px", lg: "20px" },
                fontWeight: "400",
                textAlign: "center",
                fontStyle: "normal"
              }}
            >
              El evento comienza en{" "}
            </Typography>
            <EventCountdown endDate={"18-11-2023 19:30"} />
          </React.Fragment>
        )}
      </AdviseBox>

      <Grid
        lg={9}
        md={10.5}
        xs={10.5}
        container
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left"
        }}
      >
        <Box
          width={{ xs: "100%", md: "100%", lg: "100%" }}
          sx={{ paddingBottom: { xs: "17px", md: "17px", lg: "24px" } }}
        >
          <Typography
            sx={{
              fontSize: { xs: "30px", md: "30px", lg: "40px" },
              fontWeight: "700",
              fontStyle: "normal"
            }}
          >
            "La Historia Está en tus manos"
          </Typography>
        </Box>
        <Box width={{ xs: "100%", md: "100%", lg: "100%" }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "22px", md: "22px", lg: "31px" },
              fontWeight: "700",
              fontStyle: "normal"
            }}
          >
            Concurso Carros Alegóricos 2023
          </Typography>
        </Box>
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "14px", md: "14px", lg: "20px" },
            paddingTop: { xs: "29px", md: "29px", lg: "39px" },
            fontWeight: "400",
            fontStyle: "normal"
          }}
        >
          En Octubre de 2023, Rancagua celebrará 280 años de existencia; más de
          dos siglos que han marcado la historia no sólo del territorio, sino
          que también del país.
          <br />
          <br />
          En este contexto, CDIR desea invitar a toda la comunidad educativa de
          enseñanza media de la comuna a reflotar una actividad de relevancia
          cultural e identitaria, que permitirá no sólo promover un objetivo
          común para todas las comunidades educativas de los respectivos
          establecimientos (municipales, particulares y subvencionados); sino
          que también convocará a las y los vecinos a una jornada lúdica, que
          nos permitirá re-conocer nuestra historia local, a través de la
          creatividad de las jóvenes generaciones.
          <br />
          <br />
          Es por ello que les invitamos a ser parte de la actividad nominada “La
          Historia está en tus Manos”, que se llevará a cabo el próximo 10 de
          Noviembre de 2023.
        </Typography>
        <Box
          sx={{
            paddingTop: { xs: "89px", md: "89px", lg: "124px" },
            paddingBottom: { xs: "39px", md: "39px", lg: "39px" }
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "30px", md: "30px", lg: "40px" },
              fontWeight: "700",
              fontStyle: "normal"
            }}
          >
            Objetivos
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "none", lg: "flex" },
            paddingBottom: { xs: "89px", md: "89px", lg: "124px" }
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "14px", md: "14px", lg: "20px" } }}
          >
            <ul>
              <li>
                El desfile de Carros Alegóricos “La Historia está en tus Manos”
                tiene como objetivo reflotar una actividad tradicional de
                Rancagua, en el marco de su Aniversario Nº 280, promoviendo el
                rescate de nuestra historia, cultura, identidad y aspectos que
                son parte de nuestro presente y que han forjado nuestra
                idiosincrasia.
              </li>
              <li>
                Promover la participación de la comunidad educativa de los
                establecimientos que cuenten con cursos de enseñanza media, de
                la comuna de Rancagua; contemplando a los que son de carácter
                municipal, particulares subvencionados y particulares. Con esto,
                además, se busca incentivar la sana convivencia de las y los
                estudiantes, quienes junto a docentes, asistentes de la
                educación, padres y apoderados podrán unir sus esfuerzos bajo un
                objetivo común que visibilice el trabajo en equipo que logren
                desarrollar.
              </li>
              <li>
                Incentivar el uso de espacios públicos de toda la comunidad
                rancagüina, en torno a actividades que fortalezcan nuestra
                identidad, en un espacio de encuentro familiar y
                transgeneracional.
              </li>
              <li>
                Apoyar los procesos educativos y de formación integral de las y
                los alumnos de Rancagua, en torno a la historia y tradición de
                Rancagua, quienes podrán descubrir, aprender, investigar y
                enseñar a través de una actividad lúdica y con sentido.
              </li>
              <li>
                Generar una instancia de sana competencia que de cuenta de la
                creatividad, liderazgo, trabajo en equipo e innovación de las y
                los participantes, los que marcarán un nuevo precedente en la
                historia de las tradiciones rancagüinas.
              </li>
            </ul>
          </Typography>
        </Box>
        {/* Mobile */}
        <Box
          sx={{
            display: { xs: "flex", md: "flex", lg: "none" },
            paddingBottom: { xs: "89px", md: "89px", lg: "124px" }
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "14px", md: "14px", lg: "20px" } }}
          >
            <ul>
              <li>
                El desfile de Carros Alegóricos “La Historia está en tus Manos”
                tiene como objetivo reflotar una actividad tradicional de
                Rancagua, en el marco de su Aniversario N°280.
              </li>
              <li>
                Promover la participación de la comunidad educativa de los
                establecimientos que cuenten con cursos de enseñanza media, de
                la comuna de Rancagua.
              </li>
              <li>
                Incentivar el uso de espacios públicos de toda la comunidad
                rancagüina, en torno a actividades que fortalezcan nuestra
                identidad.
              </li>
              <li>
                Apoyar los procesos educativos y de formación integral de las y
                los alumnos de Rancagua, en torno a la historia y tradición de
                Rancagua.
              </li>
              <li>
                Generar una instancia de sana competencia que de cuenta de la
                creatividad, liderazgo, trabajo en equipo e innovación de las y
                los participantes.
              </li>
            </ul>
          </Typography>
        </Box>
        <Grid
          lg={12}
          xs={12}
          md={12}
          container
          sx={{
            paddingBottom: { xs: "89px", md: "89px", lg: "124px" }
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "#131226",
              borderRadius: "10px",
              paddingX: { xs: "25px", md: "25px", lg: "103px" },
              paddingY: { xs: "40px", md: "40px", lg: "54px" },
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: { xs: "block", md: "block", lg: "flex" } }}>
              <Grid lg={6} md={12} xs={12}>
                <Typography
                  variant="h1"
                  sx={{
                    color: "white",
                    fontSize: { xs: "30px", md: "30px", lg: "40px" },
                    fontWeight: "700",
                    paddingBottom: { xs: "29px", md: "29px", lg: "" }
                  }}
                >
                  Bases Generales
                </Typography>
              </Grid>
              <Grid lg={6} md={12} xs={12}>
                <Typography
                  variant="h2"
                  sx={{
                    color: "white",
                    fontSize: { xs: "14px", md: "14px", lg: "20px" },
                    fontWeight: "400",
                    paddingBottom: { xs: "20px", md: "20px", lg: "" }
                  }}
                >
                  Si quieres conocer las Bases para ser parte de esta gran
                  Fiesta, te invitamos a revisarlas aquí
                </Typography>
                <Button
                  variant="contained"
                  href="https://firebasestorage.googleapis.com/v0/b/rancagua-admin.appspot.com/o/cdir%2Fprojects%2Fhistoria%20en%20tus%20manos%2FHISTORIA_EN_TUS_MANOS_DEF.pdf?alt=media&token=7d40297d-fcd9-4056-abf3-671a2aab1354"
                  target="_blank"
                  sx={{ background: "#FFF" }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#131226"
                    }}
                  >
                    Ver bases
                  </Typography>
                </Button>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Typography
            variant="h1"
            sx={{
              paddingBottom: { xs: "29px", md: "29px", lg: "39px" },
              fontWeight: "700",
              fontSize: { xs: "30px", md: "30px", lg: "40px" }
            }}
          >
            Categorías
          </Typography>
        </Grid>
        {categories.map((category, key) => (
          <Grid key={key} lg={4} md={12} xs={12}>
            <div style={{ paddingBottom: "28px", height: "100%" }}>
              <Accordion
                sx={{
                  background: "#FFDD53",
                  width: { xs: "100%", md: "100%", lg: "98%" },
                  height: "100%",
                  boxShadow: "none",
                  borderRadius: "10px"
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        marginTop: {
                          lg:
                            category.title ===
                              "Historia de las Mujeres de Rancagua" ||
                            category.title === "Personajes, Mitos y Leyendas" ||
                            category.title === "Costumbres e Identidad"
                              ? "-25px"
                              : "0"
                        }
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ transform: "none" }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: { xs: "16px", md: "16px", lg: "25px" },
                      fontWeight: "700",
                      paddingY: { xs: "10px", md: "10px", lg: "2%" },
                      paddingX: { xs: "10px", md: "10px", lg: "2%" },
                      width:
                        category.title === "Historia de las Mujeres de Rancagua"
                          ? "75%"
                          : "65%"
                    }}
                  >
                    {category.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    background: "#F1F1F1",
                    position: "absolute",
                    zIndex: 1
                  }}
                >
                  <Typography>{category.description}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        ))}
        <Grid lg={12} md={12} xs={12}>
          <Typography
            variant="h1"
            sx={{
              paddingTop: { xs: "61px", md: "61px", lg: "96px" },
              paddingBottom: { xs: "29px", md: "29px", lg: "72px" },
              fontWeight: "700",
              fontSize: { xs: "30px", md: "30px", lg: "40px" }
            }}
          >
            Patrocinadores
          </Typography>
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            position: "relative"
          }}
        >
          <Button
            className="prev"
            color="secondary"
            onClick={prevSponsors}
            variant="text"
            sx={{
              background: "none",
              mt: { lg: -15, xs: -11, md: -11 },
              mr: { lg: 4 }
            }}
          >
            <ArrowBackIosIcon sx={{ color: "#1C1B1F" }} />
          </Button>

          <Grid container spacing={{ xs: 10, md: 10, lg: 25 }}>
            {visibleSponsors.map((sponsors, key) => (
              <Grid key={key} lg={2} md={3} sm={4} xs={6}>
                <Box
                  sx={{
                    paddingBottom: { xs: "89px", md: "89px", lg: "124px" }
                  }}
                >
                  <Avatar
                    sx={{
                      width: { xs: "70px", md: "70px", lg: "150px" },
                      height: { xs: "70px", md: "70px", lg: "150px" }
                    }}
                    src={sponsors.src}
                    alt={sponsors.alt}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>

          <Button
            className="next"
            color="secondary"
            onClick={nextSponsors}
            sx={{
              background: "none",
              ml: { lg: -15 },
              mt: { lg: -15, xs: -11, md: -11 }
            }}
            variant="text"
          >
            <ArrowForwardIosIcon sx={{ color: "#1C1B1F" }} />
          </Button>
        </div>
        {/* ETAPA 2*/}
        <Grid lg={12} md={12} xs={12}>
          <Typography
            variant="h1"
            sx={{
              paddingBottom: { xs: "29px", md: "29px", lg: "72px" },
              fontWeight: "700",
              fontSize: { xs: "30px", md: "30px", lg: "40px" }
            }}
          >
            Liceos Participantes
          </Typography>
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            position: "relative"
          }}
        >
          <Button
            className="prev"
            color="secondary"
            onClick={prevLogos}
            variant="text"
            sx={{
              background: "none",
              mt: { lg: -15, xs: -11, md: -11 },
              mr: { lg: 4 }
            }}
          >
            <ArrowBackIosIcon sx={{ color: "#1C1B1F" }} />
          </Button>

          <Grid container spacing={{ xs: 10, md: 10, lg: 25 }}>
            {visibleLogos.map((school, key) => (
              <Grid key={key} lg={2} md={3} sm={4} xs={6}>
                <Box
                  sx={{
                    paddingBottom: { xs: "89px", md: "89px", lg: "124px" }
                  }}
                >
                  <Avatar
                    sx={{
                      width: { xs: "70px", md: "70px", lg: "150px" },
                      height: { xs: "70px", md: "70px", lg: "150px" }
                    }}
                    src={school.src}
                    alt={school.alt}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>

          <Button
            className="next"
            color="secondary"
            onClick={nextLogos}
            sx={{
              background: "none",
              ml: { lg: -15 },
              mt: { lg: -15, xs: -11, md: -11 }
            }}
            variant="text"
          >
            <ArrowForwardIosIcon sx={{ color: "#1C1B1F" }} />
          </Button>
        </div>
        <Grid lg={12} md={12} xs={12}>
          <Typography
            variant="h1"
            sx={{
              paddingBottom: { xs: "29px", md: "29px", lg: "72px" },
              fontWeight: "700",
              fontSize: { xs: "30px", md: "30px", lg: "40px" }
            }}
          >
            Resultados Votación
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          {participantsVotes.map((participant, index) => (
              <Grid key={index} xs={12} md={12} lg={4}>
                <Paper
                    sx={{
                      padding: { lg: "20px 42px", md: "20px 26px", xs: "20px 26px" },
                      background: index === 0
                          ? "#7AC4AC"
                          : index === 1
                              ? "#F4E7A8"
                              : index === 2
                                  ? "#5B7EBE"
                                  : "#FFFFFF",
                      borderRadius: "10px"
                    }}
                >
                  <Grid container>
                    <Grid lg={12} md={12} xs={12}>
                      <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "700",
                            fontSize: { xs: "37px", md: "37px", lg: "47px" }
                          }}
                      >
                        {participant.numberVotes} votos
                      </Typography>
                    </Grid>
                    <Grid lg={3} md={4} xs={4}>
                      <img
                          src={index === 0 ? "/emoji_events.svg" : index === 1 ? "/workspace_premium.svg" : "/social_leaderboard.svg"}
                          alt={`Imagen ${index + 1}`}
                          height="80px"
                          width="80px"
                      />
                    </Grid>
                    <Grid lg={9} md={8} xs={8}>
                      <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "700",
                            fontSize: { xs: "22px", md: "22px", lg: "25px" }
                          }}
                      >
                        {index === 0 ? "Primer" : index === 1 ? "Segundo" : "Tercer"} lugar
                      </Typography>
                      <Typography
                          variant="h3"
                          sx={{
                            fontWeight: "400",
                            fontSize: { xs: "14px", md: "14px", lg: "20px" }
                          }}
                      >
                        {participant.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
          ))}
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Typography
            variant="h1"
            sx={{
              paddingTop: { xs: "61px", md: "61px", lg: "96px" },
              paddingBottom: { xs: "29px", md: "29px", lg: "38px" },
              fontWeight: "700",
              fontSize: { xs: "22px", md: "22px", lg: "40px" }
            }}
          >
            #LaHistoriaEstaEnTusManos
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ paddingBottom: { lg: "124px", md: "89px", xs: "89px" } }}
        >
          {social.slice(0, 9).map((socialData, key) => (
            <Grid xs={6} md={6} lg={4} key={key}>
              <Link
                href={socialData.permalink}
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <img
                    src={
                      socialData.mediaType === "VIDEO"
                        ? socialData.cover.src
                        : socialData.mediaUrl
                    }
                    alt={socialData.mediaType}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                      maxWidth: "465px",
                      maxHeight: "303px"
                    }}
                    loading="lazy"
                  />
                </Suspense>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
