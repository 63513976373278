import React, { ReactNode } from "react";
import { Typography } from "@mui/material";

interface ParagraphProps {
  children: ReactNode;
}

export default function Paragraph({ children }: ParagraphProps) {
  return (
    <Typography
      variant="body1"
      sx={{ fontSize: { xs: "18px", md: "18px", lg: "20px" } }}
    >
      {children}
    </Typography>
  );
}
