import React, { useEffect } from "react";
//components
import { Grid, Box } from "@mui/material";
import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import { initGA, logPageView } from "../../helpers/analytics";

export default function LaCorporacion() {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <>
      <Grid container sx={{ textAlign: "left" }}>
        <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
          <Grid xs={12} md={12} lg={8}>
            <Grid
              lg={6.4}
              sx={{
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Box
                sx={{
                  paddingBottom: "29px",
                  paddingTop: "41px"
                }}
              >
                <Title>Historia</Title>
              </Box>
              <Box
                sx={{
                  lineHeight: "28px",
                  paddingBottom: "-10px"
                }}
              >
                <Paragraph>
                  Según costa en los Estatutos que dan vida a la Corporación de
                  Desarrollo e Innovación de Rancagua, esta entidad nace el 20
                  de junio de 2017, tras la aprobación unánime del Concejo
                  Municipal de la Ilustre Municipalidad de Rancagua. Se
                  establece que la Corporación naciente contará como socios
                  fundadores a la Municipalidad de Rancagua y a 10 empresas
                  privadas de la comuna.
                  <br />
                  <br />
                  Desde entonces, la Corporación ha tenido como objetivo
                  promover, fomentar, coordinar y ejecutar proyectos para el
                  desarrollo económico, productivo y social de la comuna;
                  estableciendo en sus iniciativas las improntas entregadas por
                  cada Dirección Ejecutiva.
                </Paragraph>
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12} md={12} lg={6} sx={{ marginBottom: "-10px" }}>
            <img
              src="/la_corporacion.png"
              style={{
                width: "864px",
                height: "707px"
              }}
            />
          </Grid>
        </Box>
        {/* MOBILE */}
        <Box sx={{ display: { xs: "flex", md: "flex", lg: "none" } }}>
          <Grid xs={12} md={12} lg={8}>
            <Grid
              sx={{
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Grid xs={12} md={12}>
                <img
                  src="/la_corporacion.png"
                  style={{ width: "100%", height: "371px" }}
                />
              </Grid>
              <Grid
                xs={10.5}
                md={10.5}
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                <Box
                  sx={{
                    lineHeight: "28px",
                    paddingBottom: "52px"
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: "29px",
                      paddingTop: "41px"
                    }}
                  >
                    <Title>Historia</Title>
                  </Box>
                  <Paragraph>
                    Según costa en los Estatutos que dan vida a la Corporación
                    de Desarrollo e Innovación de Rancagua, esta entidad nace el
                    20 de junio de 2017, tras la aprobación unánime del Concejo
                    Municipal de la Ilustre Municipalidad de Rancagua. Se
                    establece que la Corporación naciente contará como socios
                    fundadores a la Municipalidad de Rancagua y a 10 empresas
                    privadas de la comuna.
                    <br />
                    <br />
                    Desde entonces, la Corporación ha tenido como objetivo
                    promover, fomentar, coordinar y ejecutar proyectos para el
                    desarrollo económico, productivo y social de la comuna;
                    estableciendo en sus iniciativas las improntas entregadas
                    por cada Dirección Ejecutiva.
                  </Paragraph>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: "#b73c8e", width: "100%" }}>
          <Grid
            xs={10.5}
            md={10.5}
            lg={9}
            sx={{
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: { lg: "196px", xs: "41px", md: "41px" }
              }}
            >
              <Title>Misión</Title>
            </Box>
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: "21px"
              }}
            >
              <Paragraph>
                Entregar una mejor calidad de vida a las y los vecinos de la
                comuna considerando sus necesidades, las que motivan la
                realización de proyectos de carácter social, económicos y
                urbanos en colaboración con el sector privado y la participación
                ciudadana. De esta forma, la Corporación se transforma en un
                ente articulador entre la Ilustre Municipalidad de Rancagua, los
                organismos públicos y el mundo privado.
              </Paragraph>
            </Box>
          </Grid>
          <Grid
            xs={10.5}
            md={10.5}
            lg={9}
            sx={{
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: "72px"
              }}
            >
              <Title>Visión</Title>
            </Box>
            <Box
              sx={{
                color: "#FFFFFF",
                paddingTop: "21px",
                paddingBottom: { lg: "196px", xs: "41px", md: "41px" }
              }}
            >
              <Paragraph>
                Ser una Corporación innovadora, de excelencia a nivel regional y
                nacional, transparente y con altos estándares de calidad; que
                alcance un crecimiento y desarrollo sostenido, con procesos de
                mejora continua y que se posicione como un referente para este
                tipo de instituciones, a nivel país.
              </Paragraph>
            </Box>
          </Grid>
        </Box>
        <Grid
          xs={10.5}
          md={10.5}
          lg={9}
          sx={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Box sx={{ paddingTop: { lg: "187px", xs: "41px", md: "41px" } }}>
            <Title>Objetivos</Title>
          </Box>
          <Box
            sx={{
              paddingTop: "21px",
              lineHeight: "28px",
              paddingBottom: "349px"
            }}
          >
            <Paragraph>
              De acuerdo con los Estatutos de la Corporación de Desarrollo e
              Innovación, se indica que ésta tendrá como objetivo general:
              <br />
              <ul>
                <li>
                  El estudio, desarrollo y promoción de la economía creativa
                  local; local.
                </li>
                <li>
                  Promover, fomentar, coordinar y ejecutar proyectos para el
                  desarrollo y la innovación productiva, donde se incluyen
                  también el emprendimiento, desarrollo e innovación de los
                  procesos productivos y de servicios y transferencia
                  tecnológica en la región;
                </li>
                <li>
                  Fomentar, coordinar, promover, organizar y difundir todo
                  aquello relacionado con la seguridad ciudadana en la comuna.
                </li>
                <li>
                  Promover, fomentar, coordinar y desarrollar temáticas y
                  proyectos que digan relación a la actividad educativa a todo
                  nivel.
                </li>
                <li>
                  Estudio, desarrollo, promoción y ejecución de fines o
                  proyectos relativos a procesos tecnológicos y de energía.
                </li>
                <li>
                  Promoción y desarrollo del turismo local, regional e
                  internacional.
                </li>
              </ul>
            </Paragraph>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
