import axios from "axios";

export interface Department {
  name: string;
  description?: string;
  level?: number;
  team?: Team[];
}

export interface Team {
  name: string;
  position?: string;
  level?: number;
  contact?: Contact;
}

export interface Contact {
  email?: string;
  phone?: string;
}

export async function getDepartmentData(): Promise<Department[]> {
  try {
    const { data } = await axios.get(
      "https://services.smartrancagua.com/cdir/department"
    );
    const { rows } = data;

    const result = rows.map((department: any) => {
      const team = department.team.map((member: any) => {
        let m = member;
        if (m === "62cd8d02a5f8ee001456dbc4")
          m = {
            contact: {
              email: "jose.abarca@innovarancagua.cl",
              phone: ""
            },
            name: "José Abarca Saavedra ",
            position: "Jefe TI",
            level: 3
          };
        return m;
      });
      return { ...department, team };
    });
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const getTeamBanner = () => [
  {
    src: "/banner_corporacion.png",
    alt: "corporacion"
  }
];
