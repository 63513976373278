import React, { useEffect } from "react";
//Components
import { Box, Grid, Typography } from "@mui/material";
import { initGA, logPageView } from "../../helpers/analytics";

export default function Contacto() {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <>
      <Grid
        container
        sx={{
          textAlign: "left",
          marginLeft: "230px",
          display: { xs: "none", md: "none", lg: "flex" },
        }}
        spacing={-40}
      >
        <Grid
          xs={12}
          md={12}
          lg={6}
          sx={{
            paddingTop: "10px",
            paddingBottom: "325px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { lg: "47px", xs: "23px", md: "23px" },
              fontWeight: "700",
              paddingTop: "72px",
            }}
          >
            Información
          </Typography>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "25px", fontWeight: "700", paddingTop: "42px" }}
            >
              Horario
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
              Lunes a Jueves de 00 a 00 y 00 a 00 | Viernes de 00 a 00
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "25px", fontWeight: "700", paddingTop: "42px" }}
            >
              Teléfono
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
              722 611 618
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "25px", fontWeight: "700", paddingTop: "42px" }}
            >
              Correo
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
              contacto@innovarancagua.cl
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "25px", fontWeight: "700", paddingTop: "42px" }}
            >
              Dirección
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
              Hernán Ciudad #1053, Rancagua
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={12} lg={6} sx={{ paddingTop: "109.41px" }}>
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1tt-AIrW9kYipv42Uu1i7rWmDGLGtBm4&ehbc=2E312F"
            width="606"
            height="507"
            style={{ border: "0" }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>
      {/* MOBILE */}
      <Grid sx={{ display: { xs: "flex", md: "flex", lg: "none" } }}>
        <Grid container sx={{ textAlign: "left" }}>
          <Grid
            xs={10.5}
            md={10.5}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { lg: "47px", xs: "23px", md: "23px" },
                fontWeight: "700",
                paddingTop: "46px",
              }}
            >
              Información
            </Typography>
            <Box>
              <Typography
                variant="h1"
                sx={{ fontSize: "22px", fontWeight: "700", paddingTop: "30px" }}
              >
                Horario
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
                Lunes a Jueves de 00 a 00 y 00 a 00 | Viernes de 00 a 00
              </Typography>
            </Box>
            <Box onClick={() => (window.location.href = "tel:722611618")}>
              <Typography
                variant="h1"
                sx={{ fontSize: "22px", fontWeight: "700", paddingTop: "30px" }}
              >
                Teléfono
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
                72 2 611618
              </Typography>
            </Box>
            <Box
              onClick={() =>
                (window.location.href = "mailto:contacto@innovarancagua.cl")
              }
            >
              <Typography
                variant="h1"
                sx={{ fontSize: "22px", fontWeight: "700", paddingTop: "30px" }}
              >
                Correo
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
                contacto@innovarancagua.cl
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h1"
                sx={{ fontSize: "22px", fontWeight: "700", paddingTop: "30px" }}
              >
                Dirección
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>
                Hernán Ciudad #1053, Rancagua
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={10.5}
            md={10.5}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingTop: "58px",
              paddingBottom: "98px",
              maxWidth: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1tt-AIrW9kYipv42Uu1i7rWmDGLGtBm4&ehbc=2E312F"
              width="355"
              height="328"
              style={{ border: "0" }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
