import React, { useEffect } from "react";
//Components
import { Typography, Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { initGA, logPageView } from "../../helpers/analytics";

//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

const FooterContainer = styled("footer")(({ theme }) => ({
  backgroundColor: "#000000",
  marginTop: "auto"
}));

export default function Footer() {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });
  return (
    <>
      <FooterContainer
        sx={{
          padding: "2rem"
        }}
      >
        <Grid
          container
          xs={12}
          md={12}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "left",
            display: { xs: "none", md: "none", lg: "flex" }
          }}
        >
          <Grid
            md={12}
            style={{ textAlign: "center", padding: "1rem 0 3rem 0" }}
          >
            <img
              src="/logo_innova/logo_blanco.svg"
              alt="Logo"
              onClick={() => (window.location.href = "/")}
              style={{ width: "180px", height: "auto" }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={2} sx={{ paddingRight: "12px" }}>
            <Typography
              onClick={() => (window.location.href = "/la_corporacion")}
              variant="h1"
              sx={{
                color: "#fff",
                fontWeight: "bold",
                paddingBottom: "8px",
                fontSize: "16px"
              }}
            >
              La Corporación
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/la_corporacion")}
            >
              Historia
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/la_corporacion")}
            >
              Misión
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              Visión
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              Objetivos
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={2} sx={{ paddingRight: "12px" }}>
            {/* Columna 3 */}
            <Typography
              onClick={() => (window.location.href = "/nosotras_nosotros")}
              variant="h1"
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingBottom: "8px",
                fontSize: "16px"
              }}
            >
              Nosotras y Nosotros
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/nosotras_nosotros")}
            >
              Directorio
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/nosotras_nosotros")}
            >
              Organigrama
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/nosotras_nosotros")}
            >
              Áreas
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={2} sx={{ paddingRight: "12px" }}>
            {/* Columna 4 */}
            <Typography
              onClick={() => (window.location.href = "/proyectos")}
              variant="h1"
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingBottom: "13px",
                fontSize: "16px"
              }}
            >
              Proyectos
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/innovacion-social")}
            >
              Innovación Social
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() =>
                (window.location.href = "/desarrollo-tecnologías-informacion")
              }
            >
              Desarrollo
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() =>
                (window.location.href = "/seminarios-y-capacitaciones")
              }
            >
              Seminarios y Capacitaciones
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() => (window.location.href = "/generando-identidad")}
            >
              Generando Identidad
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() =>
                (window.location.href = "https://rancagua.digital/#/")
              }
            >
              Rancagua Digital
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={2}
            sx={{ paddingRight: "12px", height: "auto" }}
          >
            {/* Columna 5 */}
            <Typography
              onClick={() => (window.location.href = "/noticias")}
              variant="h1"
              style={{
                color: "#fff",
                fontWeight: "bold",
                /*    paddingBottom: "8px", */
                fontSize: "16px"
              }}
            >
              Noticias y Documentos
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff" /* , paddingBottom: "13px"  */ }}
              onClick={() => (window.location.href = "/noticias")}
            >
              Noticias
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff" /* , paddingBottom: "13px" */ }}
              onClick={() => (window.location.href = "/noticias")}
            >
              Documentos
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={2} sx={{ paddingRight: "12px" }}>
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontWeight: "bold",
                paddingBottom: "13px",
                fontSize: "16px"
              }}
            >
              Transparencia
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060")
              }
            >
              Solicitud de Información
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.leylobby.gob.cl/instituciones/CM060")
              }
            >
              Ley del Lobby
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060")
              }
            >
              Transparencia Activa
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              Licitaciones
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff" }}
              onClick={() => (window.location.href = "/terminos_condiciones")}
            >
              Terminos y Condiciones
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={12} lg={2} sx={{ paddingRight: "12px" }}>
            {/* Columna 6 */}
            <Typography
              onClick={() => (window.location.href = "/contacto")}
              variant="h1"
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingBottom: "8px",
                fontSize: "16px"
              }}
            >
              Contacto
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              Hernán Ciudad #1053 Rancagua.
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              72 2 611618
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              Lunes a Jueves de 8:30 a 18:00 Viernes de 8:30 a 14:00
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", paddingBottom: "13px" }}
            >
              contacto@innovarancagua.cl
            </Typography>
            <Typography
              variant="h1"
              style={{
                color: "#fff",
                fontWeight: "bold",
                paddingBottom: "13px",
                paddingTop: "33px",
                fontSize: "16px"
              }}
            >
              Síguenos
            </Typography>
            <Box display="flex">
              <Link
                href="https://www.facebook.com/InnovaRancagua"
                target="_blank"
              >
                <FacebookIcon sx={{ color: "#fff" }} />
              </Link>
              <Link
                href="https://www.instagram.com/desarrollo_innovacion/"
                target="_blank"
              >
                <InstagramIcon sx={{ color: "#fff", marginLeft: "15px" }} />
              </Link>
              <Link href="https://twitter.com/RancaguaInnova" target="_blank">
                <TwitterIcon sx={{ color: "#fff", marginLeft: "15px" }} />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </FooterContainer>
      {/* MOBILE */}
      <Box
        sx={{
          textAlign: "left",
          display: { xs: "flex", md: "flex", lg: "none" },
          backgroundColor: "#000000",
          marginTop: "-140px",
          maxHeight: "auto"
        }}
      >
        <Grid
          container
          xs={10.5}
          md={10.5}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "left"
          }}
        >
          <Grid xs={12} md={12} sx={{ textAlign: "left", padding: "2rem 0" }}>
            <img
              src="/logo_innova/logo_blanco.svg"
              onClick={() => (window.location.href = "/")}
              alt="Logo"
              width="50%"
              height="auto"
            />
          </Grid>
          <Grid xs={6} md={6}>
            <Typography
              onClick={() => (window.location.href = "/la_corporacion")}
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              La Corporación
            </Typography>
          </Grid>
          <Grid xs={6} md={6} lg={6}>
            <Typography
              onClick={() => (window.location.href = "/nosotras_nosotros")}
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              Nosotras y
            </Typography>
            <Typography
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
              onClick={() => (window.location.href = "/nosotras_nosotros")}
            >
              Nosotros
            </Typography>
          </Grid>
          <Grid xs={6} md={6} sx={{ paddingTop: "36px" }}>
            <Typography
              onClick={() => (window.location.href = "/proyectos")}
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              Proyectos
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() => (window.location.href = "/innovacion-social")}
            >
              Innovacion Social
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() =>
                (window.location.href = "/desarrollo-tecnologías-informacion")
              }
            >
              Desarrollo
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() =>
                (window.location.href = "/seminarios-y-capacitaciones")
              }
            >
              Seminiario y Capacitaciones
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() => (window.location.href = "/generando-identidad")}
            >
              Generando Identidad
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
            >
              Rancagua Digital
            </Typography>
          </Grid>
          <Grid xs={6} md={6} lg={6} sx={{ paddingTop: "36px" }}>
            <Typography
              onClick={() => (window.location.href = "/noticias")}
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              Noticias y Documentos
            </Typography>
          </Grid>
          <Grid xs={6} md={6} lg={6} sx={{ paddingTop: "36px" }}>
            <Typography
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              Transparencia
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() =>
                (window.location.href =
                  "https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060")
              }
            >
              Solicitud de
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400"
              }}
              onClick={() =>
                (window.location.href =
                  "https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060")
              }
            >
              Iformación
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() =>
                (window.location.href =
                  "https://www.leylobby.gob.cl/instituciones/CM060")
              }
            >
              Ley del Lobby
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() =>
                (window.location.href =
                  "https://www.portaltransparencia.cl/PortalPdT/directorio-de-organismos-regulados/?org=CM060    ")
              }
            >
              Transparencia Activa
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
            >
              Licitacones
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
              onClick={() => (window.location.href = "/terminos_condiciones")}
            >
              Terminos y Condiciones
            </Typography>
          </Grid>
          <Grid xs={6} md={6} lg={6} sx={{ paddingTop: "36px" }}>
            <Typography
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              Síguenos
            </Typography>
            <Box display="flex">
              <Link
                href="https://www.facebook.com/InnovaRancagua"
                target="_blank"
                sx={{ paddingTop: "13px" }}
              >
                <FacebookIcon sx={{ color: "#fff" }} />
              </Link>
              <Link
                href="https://www.instagram.com/desarrollo_innovacion/"
                target="_blank"
                sx={{ paddingTop: "13px" }}
              >
                <InstagramIcon sx={{ color: "#fff", marginLeft: "15px" }} />
              </Link>
              <Link
                href="https://twitter.com/RancaguaInnova"
                target="_blank"
                sx={{ paddingTop: "13px" }}
              >
                <TwitterIcon sx={{ color: "#fff", marginLeft: "15px" }} />
              </Link>
            </Box>
          </Grid>
          <Grid xs={12} md={12} lg={6} sx={{ paddingTop: "36px" }}>
            <Typography
              onClick={() => (window.location.href = "/contacto")}
              variant="h1"
              style={{ color: "#fff", fontWeight: "700", fontSize: "16px" }}
            >
              Contacto
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
            >
              <FmdGoodOutlinedIcon
                sx={{ verticalAlign: "middle", marginRight: "5px" }}
              />{" "}
              Hernán Ciudad #1053 Rancagua.
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
            >
              <CallOutlinedIcon
                sx={{ verticalAlign: "middle", marginRight: "5px" }}
              />{" "}
              72 2 611618
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px"
              }}
            >
              <AccessTimeOutlinedIcon
                sx={{ verticalAlign: "middle", marginRight: "5px" }}
              />{" "}
              Lunes a Jueves de 8:30 a 18:00
            </Typography>
            {/* <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "32px",
              }}
            > a 18:00
            </Typography> */}
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "32px"
              }}
            >
              Viernes de 8:30 a 14:00
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                paddingTop: "13px",
                paddingBottom: "56px"
              }}
            >
              <MailOutlineOutlinedIcon
                sx={{ verticalAlign: "middle", marginRight: "5px" }}
              />{" "}
              contacto@innovarancagua.cl
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
